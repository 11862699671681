"use client";

import { useEffect } from "react";

const MswComponent = () => {
  useEffect(() => {
    const init = async () => {
      if (process.env.NEXT_PUBLIC_API_MOCKING === "enabled") {
        const initMsw = await import("./index").then((res) => res.initMocks);
        await initMsw();
      }
    };

    void init();
  }, []);

  return null;
};

export default MswComponent;
